<template>
  <div id="app">
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
    >
    <div
      id="reset_modal"
      class="modal"
    >
      <div class="modal-background" />
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Reset
          </p>
        </header>
        <section class="modal-card-body">
          Reset and start a new part search?
        </section>
        <footer class="modal-card-foot is-right">
          <div
            class="buttons is-right"
            style="width: 100%;"
          >
            <button 
              class="button" 
              @click="closeResetModal"
            >
              Cancel
            </button>
            <button 
              class="button is-danger"
              @click="reset"
            >
              Reset
            </button>
          </div>
        </footer>
      </div>
    </div>
    <div
      id="token_modal"
      class="modal"
    >
      <div class="modal-background" />
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Access Token
          </p>
        </header>
        <section class="modal-card-body">
          <div>
            <span id="token_message"></span>
          </div>
          <div>
            <input class="input" type="password" v-model="access_token" placeholder="Access Token">
          </div>
        </section>
        <footer class="modal-card-foot is-right">
          <div
            class="buttons is-right"
            style="width: 100%;"
          >
            <button 
              class="button is-success"
              @click="search_stream"
            >
              Submit
            </button>
          </div>
        </footer>
      </div>
    </div>
    <section 
      v-if="current_items.length == 0"
      id="search_form" 
      class="container is-fluid"
    >
      <textarea
        id="parts_text"
        v-model="parts_text"
        class="textarea"
        rows="10" 
        placeholder="Part Number(s)"
        @input="parts_text=$event.target.value.toUpperCase()"
      />
      <div>
        <button
          id="search_button"
          class="button is-info"
          style="width: 100%;"
          type="button"
          :disabled="searchEnableDisable"
          @click="search_stream"
        >
          Search
        </button>
      </div>
    </section>
    <div
      v-if="current_items.length > 0"
      id="content"
      v-touch:swipe="swipe_event"
      class="container"
    >
      <transition :name="transition" mode="out-in">
        <section id="search_data" :key="page_index">
          <div id="search_data_page">
            <div id="search_data_title" class="title">
              {{ current_items[page_index].site }}
            </div>
            <div
              v-for="(row, row_idx) in current_items[page_index].data"
              :key="current_items[page_index].site + row_idx"
              class="data-container"
            >
              <div
                v-for="(item, key) in row"
                :key="current_items[page_index].site + row_idx + key"
                class="data-item"
              >
                <span class="data-key">{{ key }}</span>
                <span class="data-value">{{ item }}</span>
              </div>
            </div>
          </div>
        </section>
      </transition>
    </div>
    <section id="nav_bottom" v-if="parts_array.length > 0">
      <section id="navdots">
        <div
          v-if="current_items.length > 0"
          class="navdot navdot-dotmove"
        >
          <ul>
            <li
              v-for="(item, item_idx) in current_items"
              :key="'navdot-'+item.site"
              :data-index="item_idx"
              :class="checkEmptyPage(item.data)"
            >
              <a @click="navClick(item_idx)"/>
            </li>
            <li id="nav_selected">
              <!-- movable dot -->
            </li>
          </ul>
        </div>
      </section>
      <section id="navparts">
        <div class="navparts-item">
          <button class="button is-light" @click="showResetModal">
            <span class="icon" >
              <i class="material-icons" style="color:gray;">refresh</i>
            </span>
          </button>
        </div>
        
        <div class="navparts-item">
          <div v-if="searching" class="" >
            <a class="button is-loading is-light is-small" title="Searching for parts..."/>
          </div>
        </div>
        

        <div class="navparts-item">
          <div class="select is-small" style="margin: 0em 2em;">
            <select id="part_select" class="select is-small" 
                    v-model="part_index">
              <option disabled value="">Select Part</option>
              <option v-for="(part, key) in parts_array" 
                      :value="key"
                      :key="key">
                {{part}}
              </option>
            </select>
          </div>
        </div>
        <div class="navparts-item">
          <span style="margin-right:1.5em;">
            {{part_index + 1}} of {{parts_array.length}}
          </span>
        </div>
        <div class="navparts-item">
          <div class="buttons has-addons" style="margin-bottom:0px;">
            <button id='prev_part_button' class="button is-light" type="button" style="margin-bottom:0px;"
                    title="Previous" @click="previousPart">
              <span class="icon">
                <i class="material-icons" style="color:gray;">navigate_before</i>
              </span>
            </button>
            <button id='next_part_button' class="button is-light" type="button" style="margin-bottom:0px;"
                    title="Next" @click="nextPart">
              <span class="icon" >
                <i class="material-icons" style="color:gray;">navigate_next</i>
              </span>
            </button>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import '@/assets/bulma.min.css'
import moment from 'moment';
var numeral = require('numeral');
// import { slider, slideritem } from 'vue-concise-slider'
import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(Vue2TouchEvents)

export default {
  name: 'App',
  components: {
    // slider,
    // slideritem
  },
  data() {
    return {
      searchDataRaw: [],
      parts_text: '',
      parts_array: [],
      part_index: 0,
      part_selected: 'NONE',
      page_index: 0,
      transition: '', // either "changedata-next" or "changedata-previous"
      slider_key: 0,
      searching: false,
      access_token: ''
    }
  },
  computed: {
    current_items(){
      let items = []
      for(let item of this.searchData){
        if(item.part==this.part_selected){
          items.push(item)
        }
      }
      return items
    },
    // part_selected(){
    //   return this.parts_text.trim()
    // },
    searchEnableDisable(){
      return !(this.parts_text.length>=3)
    },
    searchData: function(){
      // Clean the raw search data and return data
      // containing a single datateble for every
      // site-part pair
      let raw_data = [...this.searchDataRaw]  // Copy to new array
      let cleaned_search_data = []
      let quote_sale = ['Quotes', 'Sales']
      let inventory = ['Greenwood Ponca Inventory', 'GlobalParts Inventory', 'Inventory ComponentControl', 'Inventory GlobalParts', ]
      // let research_subs_dict = this.research_subs_dict
      var research_subs_dict = {}
      // Loop through raw data
      for(let raw_idx in raw_data){
        let raw_site = raw_data[raw_idx].site
        let raw_part = raw_data[raw_idx].part
        let raw_sub = raw_data[raw_idx].substitute_for
        // Part research could be on substitutes, so keep track of the reverse relations
        if(raw_sub){
          if(!Object.prototype.hasOwnProperty.call(research_subs_dict, raw_sub)){
            research_subs_dict[raw_sub] = []
          }
          if(!research_subs_dict[raw_sub].includes(raw_part)){
            research_subs_dict[raw_sub].push(raw_part)
          }
        }
        let idx_match = -1
        let idx_overwrite = -1
        // Compare raw data to cleaned data to see if raw should be appended to clean
        for(let clean_idx in cleaned_search_data){
          let clean_site = cleaned_search_data[clean_idx].site
          let clean_part = cleaned_search_data[clean_idx].part

          // Handle inventory data
          if((clean_site==raw_site) & inventory.includes(clean_site) & (clean_part==raw_part)){
            idx_overwrite = clean_idx
            break
          }
          // Handle quote/sales data
          if(quote_sale.includes(clean_site)){
            if((clean_site==raw_site) &  (clean_part==raw_part)){
              idx_overwrite = clean_idx
              break
            }
          }
          // Handle search data
          if((clean_site==raw_site) & ((clean_part==raw_sub) | (clean_part==raw_part))){
            idx_match = clean_idx
            break
          }
          
        }
        if(idx_overwrite > -1){
          let overwrite_data = JSON.parse(JSON.stringify(raw_data[raw_idx]));
          cleaned_search_data[idx_overwrite] = overwrite_data
        }else if(idx_match < 0){
          // Create a new datatable (deep clone the original object)
          let copy_data = JSON.parse(JSON.stringify(raw_data[raw_idx])); //Object.assign({}, raw_data[raw_idx]) // {...raw_data[raw_idx]}  // Copy to new object
          cleaned_search_data.push(copy_data)
        }else{
          // Append to the existing data
          let append_data = [...raw_data[raw_idx].data]  // Copy to new array
          cleaned_search_data[idx_match].data.push(...append_data)  // Append each element to cleaned data
          // Remove any Data=None rows
        }
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.research_subs_dict = research_subs_dict
      return cleaned_search_data
    },
  },
  watch: {
    current_items(){
      this.slider_key += 1
    },
    part_index(){
      this.part_selected = this.parts_array[this.part_index]
    }
  },
  mounted() {
    // this.ajax_get_part()
    this.slider_key += 1
    if(localStorage.access_token){
      this.access_token = localStorage.access_token
    }
  },
  methods: {
    previousPart(){
      let prev = this.part_index - 1
      if (prev >= 0) {
        this.part_index = prev  // Watcher on part_index
      }
    },
    nextPart(){
      let prev = this.part_index + 1
      if (prev < this.parts_array.length) {
        this.part_index = prev  // Watcher on part_index
      }
    },
    checkEmptyPage(data){
      if(data.length == 0){
        return 'empty_page'
      }
    },
    navClick(idx){
      console.log('page' + idx)
      
      if(this.page_index>idx){
        this.transition = 'changedata-previous'
      }else{
        this.transition = 'changedata-next'
      }
      this.page_index = idx
      // let translate_percent = this.page_index.toString() + '00'
      this.move_nav(this.page_index)
    },
    showResetModal(){
      document.getElementById('reset_modal').classList.add('is-active')
    },
    closeResetModal(){
      document.getElementById('reset_modal').classList.remove('is-active')
    },
    showTokenModal(message=''){
      document.getElementById('token_message').innerHTML = message
      document.getElementById('token_modal').classList.add('is-active')
    },
    closeTokenModal(){
      document.getElementById('token_modal').classList.remove('is-active')
    },
    reset(){
      this.searchDataRaw = []
      this.parts_text = ''
      this.parts_array = []
      this.part_index = 0
      this.page_index = 0
      this.part_selected = ''
      this.closeResetModal()
    },
    move_nav(translate_percent){
      let nav = document.getElementById('nav_selected')
      // nav.style.webkit-transform = 'translateX(0%)'
      nav.style.transform = `translateX(${translate_percent}00%)`
    },
    swipe_event (direction) {
      console.log(direction) 
      if(direction=='right'){
        if(this.page_index>0){
          this.transition = 'changedata-previous'
          this.page_index -= 1
          // let translate_percent = this.page_index.toString() + '00'
          this.move_nav(this.page_index)
        }
      }
      if(direction=='left'){
        if(this.page_index<this.current_items.length-1){
          this.transition = 'changedata-next'
          this.page_index += 1
          this.move_nav(this.page_index)
        }
      }
    },
    parsePN(parts_text){
      // Split part numbers text into array
      // Break on return or tab, upper case, only single space allowed in part number
      let parts_array = parts_text.toUpperCase().replace(/[ \t]+/, ' ').split(/\n/); 
      for(let i=parts_array.length-1; i>=0; i--){
        // Strip whitespace
        parts_array[i] = parts_array[i].replace(/(^\s+|\s+$)/g,'')
        // Remove blanks
        if ((parts_array[i]) == ""){
          parts_array.splice(i,1);
        }
      }
      return parts_array
    },
    test_request(){
      // Set cors headers
      const headers = new Headers({
        
      })
      // Retrieve NDJSON from the server
      const response = fetch('https://greenwood.parts/ajax/stream/mobile_part', //'https://greenwood.parts/ajax/stream/mobile_part'  // '/ajax/stream/mobile_part'  //  'https://greenwood.parts/ajax/test'
            {method:'POST', 
              headers: headers,
              body:JSON.stringify({'json_post': 'data'})
            });
      const results = response.body
      console.log(results)
    },
    search_stream(){
      this.closeTokenModal()
      if(this.access_token == ''){
        this.showTokenModal()
        return
      }
      let parts_text = this.parts_text
      this.parts_array = this.parsePN(parts_text)
      this.part_index = 0
      this.part_selected = this.parts_array[this.part_index]
      async function process(writeMethod, json_post) {
        // Set cors headers
        const headers = new Headers({
          
        })
        // Retrieve NDJSON from the server
        const response = await fetch('https://greenwood.parts/ajax/stream/mobile_part', //'https://greenwood.parts/ajax/stream/mobile_part'  // '/ajax/stream/mobile_part'
              {method:'POST', 
                headers: headers,
                body:JSON.stringify(json_post)
              });

        const results = response.body
            // From bytes to text:
            // .pipeThrough(new TextDecoder())

        // Loop through the results and write to the DOM
        writeMethod(results.getReader());
      }
      this.searching = true
      // let search_customer = this.search_customer
      // let search_type = this.search_type
      // let customer_ref = this.search_reference
      let json_post = {'part_number':parts_text, 'access_token': this.access_token}
      // if(this.search_action_id > 0){
      //   // If we have a search action, pass it back to avoid creating a new one and overwriting current quoteData
      //   json_post['search_action_id'] = this.search_action_id
      // }else if(search_action_id!=null){
      //   // Search action from RFQ file upload
      //   json_post['search_action_id'] = search_action_id
      // }
      process(this.writeToDOM, json_post)
    },
    writeToDOM(reader) {
      reader.read().then(
        ({ value, done }) => {
          if (done) {
            this.end_search()
          } else {
            let d = new TextDecoder
            let val = d.decode(value)
            console.log(val)
            if (val.endsWith('{{ENDREC}}')){
              // console.log('closed')
              // if(this.response_buffer == ''){
              //   // Nothing to buffer, so concat results
              //   let parsed = JSON.parse(val)
              // }else{
              //   // Append to buffer then concat results from buffer
              //   this.response_buffer = this.response_buffer + val
              //   let parsed = JSON.parse(this.response_buffer)
              // }

              // Append to response buffer then move to finished buffer
              // to avoid race conditions
              let finished_buffer = this.response_buffer + val
              // Clear buffer
              this.response_buffer = ''

              // // Concat results from buffer to searchDataRaw object
              // console.log('--------- compleded start ---------')
              // console.log(finished_buffer)
              // console.log('-----------------------------------')
              let split = finished_buffer.split('{{ENDREC}}')
              for (let s in split){
                // Each record can now be edited before display
                let record = split[s]
                // Remove blanks
                if (record == '' | record == 'undefined'){
                  continue
                }
                console.log(record)
                let parsed = JSON.parse(record)
                if (Object.prototype.hasOwnProperty.call(parsed, "duplicate_reference")){
                  // Alert user that someone is working on a quote with the same reference number
                  this.duplicate_reference = parsed.duplicate_reference
                  // Show alert dialog
                }else if (Object.prototype.hasOwnProperty.call(parsed, 'pulse')){
                  // Updates on the status of the result stream
                  let pulse = parsed.pulse
                  if(Object.prototype.hasOwnProperty.call(pulse, 'error')){
                    alert("Something went wrong while streaming:\n\n"+pulse.error)
                  }
                  if(Object.prototype.hasOwnProperty.call(pulse, 'token')){
                    this.showTokenModal("Invalid token, please try again")
                  }
                }else if(Object.prototype.hasOwnProperty.call(parsed, 'search_action_id')){
                  // Keep track of the search action id in order
                  // to append to all future actions
                  let search_action_id = parsed.search_action_id
                  this.search_action_id = search_action_id
                  // Retrieve the quote data from stream parts
                  // or saved quote
                  if (Object.prototype.hasOwnProperty.call(parsed, 'quote_items')){
                    // Load existing quote from MyQuotes
                    let quote_items = parsed.quote_items
                    this.quoteData = quote_items
                    // Load data into q_ quote variables
                    this.partChange()
                  }
                // }else if(Object.prototype.hasOwnProperty.call(parsed, 'admin_auth')){
                //   this.admin_auth = parsed.admin_auth
                }else if(Object.prototype.hasOwnProperty.call(parsed, 'note_type')){
                  // Separate the notes into sales and admin
                  if (parsed.note_type == 'sales'){
                    this.sales_notes.push(parsed)
                  }else if(parsed.note_type == 'admin'){
                    this.admin_notes.push(parsed)
                  }
                }else{
                  if(parsed[0]==false){
                    continue
                  }else if(parsed[0].site == 'banner_alerts'){
                    // There could be multiple sources for banner alerts, so concatenate
                    this.bannerData = this.bannerData.concat(parsed[0].data)
                    // DON'T create a table
                    continue
                  }else if(parsed[0].site == 'pricing_rule_match'){
                    // Data contains single part number so extend array
                    for (let pr of parsed){
                      this.pricing_rule_match = this.pricing_rule_match.concat(pr.data)
                    }
                    // DON'T create a table
                    continue
                  }else if(parsed[0].site == 'partsbase_pricing'){
                    // Data contains single part number so extend array
                    for (let pr of parsed){
                      this.partsbase_pricing = this.partsbase_pricing.concat(pr)
                    }
                    // DON'T create a table
                    continue
                  }else if(parsed[0].site == 'partsbase_market'){
                    // Data contains single part number so extend array
                    for (let pr of parsed){
                      this.partsbase_market = this.partsbase_market.concat(pr)
                    }
                    // DON'T create a table
                    continue
                  }else if(parsed[0].site == 'Sales' & parsed[0].data.length > 0){
                    if (Object.prototype.hasOwnProperty.call(parsed[0].data[0], 'part_number')){
                      // format sales data
                      for(let i=0; i<parsed[0].data.length; i++){
                        parsed[0].data[i].unit_cost = numeral(parsed[0].data[i].unit_cost).format('$0,0,0,0,0.00')
                        parsed[0].data[i].total_cost = numeral(parsed[0].data[i].total_cost).format('$0,0,0,0,0.00')
                        parsed[0].data[i].unit_price = numeral(parsed[0].data[i].unit_price).format('$0,0,0,0,0.00')
                        parsed[0].data[i].total_price = numeral(parsed[0].data[i].total_price).format('$0,0,0,0,0.00')
                        parsed[0].data[i].profit = numeral(parsed[0].data[i].profit).format('$0,0,0,0,0.00')
                        parsed[0].data[i].margin = numeral(parsed[0].data[i].margin).format('0.00%')
                        parsed[0].data[i].time_created = moment(parsed[0].data[i].time_created).format('YYYY-MM-DD')
                        parsed[0].data[i].post_date = moment(parsed[0].data[i].post_date).format('YYYY-MM-DD')
                      }
                    }
                  }else if(parsed[0].site == 'Quotes' & parsed[0].data.length > 0){
                    if (Object.prototype.hasOwnProperty.call(parsed[0].data[0], 'part_number')){
                      // format quote data
                      for(let i=0; i<parsed[0].data.length; i++){
                        parsed[0].data[i].unit_cost = numeral(parsed[0].data[i].unit_cost).format('$0,0,0,0,0.00')
                        parsed[0].data[i].quote = numeral(parsed[0].data[i].quote).format('$0,0,0,0,0.00')
                        parsed[0].data[i].ext_quote = numeral(parsed[0].data[i].ext_quote).format('$0,0,0,0,0.00')
                        parsed[0].data[i].margin = numeral(parsed[0].data[i].margin/100).format('0.0%')
                        parsed[0].data[i].time_created = moment(parsed[0].data[i].time_created).format('YYYY-MM-DD')
                      }
                    }
                  }else if(parsed[0].site == 'Inventory ComponentControl' & parsed[0].data.length > 0){
                    // This is the old site name, but same info as "Greenwood Ponca Inventory"
                    // Keep this for backward compatibility with old searches
                    if (Object.prototype.hasOwnProperty.call(parsed[0].data[0], 'part_number')){
                      // format quote data
                      for(let i=0; i<parsed[0].data.length; i++){
                        parsed[0].data[i].unit_cost = numeral(parsed[0].data[i].unit_cost).format('$0,0,0,0,0.00')
                        // parsed[0].data[i].time_created = moment(parsed[0].data[i].time_created).format('YYYY-MM-DD')
                      }
                    }
                  }else if(parsed[0].site == 'Greenwood Ponca Inventory' & parsed[0].data.length > 0){
                    if (Object.prototype.hasOwnProperty.call(parsed[0].data[0], 'part_number')){
                      // format quote data
                      for(let i=0; i<parsed[0].data.length; i++){
                        parsed[0].data[i].unit_cost = numeral(parsed[0].data[i].unit_cost).format('$0,0,0,0,0.00')
                        // parsed[0].data[i].time_created = moment(parsed[0].data[i].time_created).format('YYYY-MM-DD')
                      }
                    }
                  }else if(parsed[0].site == 'Open Sales Orders' & parsed[0].data.length > 0){
                    if (Object.prototype.hasOwnProperty.call(parsed[0].data[0], 'part_number')){
                      // format quote data
                      for(let i=0; i<parsed[0].data.length; i++){
                        parsed[0].data[i].est_cost = numeral(parsed[0].data[i].est_cost).format('$0,0,0,0,0.00')
                        parsed[0].data[i].unit_price = numeral(parsed[0].data[i].unit_price).format('$0,0,0,0,0.00')
                        parsed[0].data[i].total_price = numeral(parsed[0].data[i].total_price).format('$0,0,0,0,0.00')
                        parsed[0].data[i].total_cost = numeral(parsed[0].data[i].total_cost).format('$0,0,0,0,0.00')
                        parsed[0].data[i].profit = numeral(parsed[0].data[i].profit).format('$0,0,0,0,0.00')
                        // parsed[0].data[i].time_created = moment(parsed[0].data[i].time_created).format('YYYY-MM-DD')
                      }
                    }
                  }else if(parsed[0].site == 'Open Purchase Orders' & parsed[0].data.length > 0){
                    if (Object.prototype.hasOwnProperty.call(parsed[0].data[0], 'part_number')){
                      // format quote data
                      for(let i=0; i<parsed[0].data.length; i++){
                        parsed[0].data[i].unit_cost = numeral(parsed[0].data[i].unit_cost).format('$0,0,0,0,0.00')
                        parsed[0].data[i].total_cost = numeral(parsed[0].data[i].total_cost).format('$0,0,0,0,0.00')
                        // parsed[0].data[i].time_created = moment(parsed[0].data[i].time_created).format('YYYY-MM-DD')
                      }
                    }
                  }else if(parsed[0].site == 'ILS' & parsed[0].data.length>0){
                    // Remove bad ILS companies
                    let rem_comp = ['RAP LLC (Ross Aviation Parts) (CDKX)','Banyan Air Service (7253)','Seattle Aviation Solutions (C9FN)', 'RAP LLC (Ross Aviation Parts)','Banyan Air Service','Seattle Aviation Solutions']
                    // ILS returns multiple records at once, so clean all of them (not just [0])
                    for(let p in parsed){
                      for(let i=parsed[p].data.length - 1; i>=0; i--){
                        // Start from the end of the array and remove objects if needed
                        let row = parsed[p].data[i]
                        if(Object.prototype.hasOwnProperty.call(row, 'company')){
                          if(rem_comp.indexOf(row.company)>=0){
                            parsed[p].data.splice(i,1)
                          }
                        }
                      }
                      for(let i=parsed[p].data.length - 1; i>=0; i--){
                        // Start from the end of the array and remove objects if needed
                        let row = parsed[p].data[i]
                        // Remove if Quantity = "RQST"
                        if(Object.prototype.hasOwnProperty.call(row, 'quantity')){
                          if(row.quantity == "RQST"){
                            parsed[p].data.splice(i,1)
                          }
                        }
                      }
                    }
                  }else if(parsed[0].site == 'Textron Spread'){
                    // Save data for alert
                    for(let spread of parsed){
                      if(spread.alert == 'True'){
                        this.alertData.push(spread.data[0])
                      }
                    }
                    
                  }
                  // Everything else is search data
                  // Add this record to the search data
                  this.searchDataRaw = this.searchDataRaw.concat(parsed)
                }
                
                
              }
              //console.log('---------  compleded end  ---------')

            }else{
              //console.log('still open...')
              this.response_buffer = this.response_buffer + val
            }
            // let parsed = JSON.parse(val)
            // this.stream_data = this.stream_data + d.decode(value)
            // // eslint-disable-next-line no-unused-vars
            // //let thisdata = JSON.parse(d.decode(value))
            // this.searchData = this.searchData.concat(parsed)
            
              // // Build up the values
              // this.stream_data = this.stream_data + value

              // Recursively call
              this.writeToDOM(reader);
          }
        },
        e => console.error("The stream became errored and cannot be read from!", e)
      );
    },
    end_search(){
      this.searching = false
      console.log("Done streaming")
    },
    // ajax_get_part(){
    //   let url = '/ajax/stream/mobile_part' // 'https://greenwood.parts/ajax/test' // 

    //   function change_values(token){
    //     this.token = token
    //   }
    //   fetch(url, {
    //     method:'POST',
    //     body: JSON.stringify({test: 'this_test'})
    //   })
    //   .then(res => res.json())
    //   .then(res => {
    //     if (res.success){
    //       // Return a note object containing note/user/timestamp
    //       console.log(res);
    //       change_values(res.token)
    //     }else{
    //       alert('Error: Values not changed')
    //     }
    //   })
    //   .catch(e => {
    //     console.error(JSON.stringify(e.message));
    //   });
    // },
  }
}
</script>

<style>
:root{
  --top-margin: 60px;
  --navdots-height: 30px;
  --navparts-height: 40px;
  --navparts-padding-top: 0px;
  --navparts-margin-top: 5px;
  --navparts-outer-height: calc( var(--navparts-height) + var(--navparts-padding-top) + var(--navparts-margin-top));
  --nav-bottom-height: calc(var(--navdots-height) + var(--navparts-outer-height));
  --search-data-min-height: calc(100vh - var(--top-margin) - var(--nav-bottom-height));
  --page-transition-time: 0.5s;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: var(--top-margin);
}
#content {
  min-height: calc(100vh - var(--top-margin));
  /* Leave room for content to scroll above the fixed nav bar */
  padding-bottom: var(--nav-bottom-height);
}
#search_data{
  min-height: var(--search-data-min-height);
  display: flex;
  flex-direction: row;
}
#search_data_title{
  
}
#search_data_page{
  /* position: relative;
  top: 0px; */
  width: 100%;
}
#nav_bottom{
  position: fixed;
  bottom: 0px;
  width: 100%;
  /* transform: translate3d(0,0,0); */
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 88%, rgba(255,255,255,0) 100%);
}
#navdots{
  height: var(--navdots-height);
  padding-top: 10px;
  /* background: rgb(255,255,255); */
  /* background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 68%, rgba(255,255,255,0) 100%); */
}
#navparts{
  height: var(--navparts-height);
  padding-top: var(--navparts-padding-top);
  margin-top: var(--navparts-margin-top);
  background: rgba(0, 0, 0, 0.01);
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
}
.navparts-item{
  align-self: center;
}

/* Data containers */
.data-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-top: 2px darkgray solid;
  border-bottom: 2px darkgray solid;
  margin-bottom: 1em;
}
.data-container:nth-child(odd){
  background: rgba(50, 152, 220, 0.05);
}
.data-container:nth-child(even){
  
}
.data-item{
  width: 33.3333333333%;
  padding: 2%;
  display:flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.75);
}
.data-item:nth-child(6n+1){
  background: rgba(0, 0, 0, 0.05);
}
.data-item:nth-child(6n+2){
  background: rgba(0, 0, 0, 0.05);
}
.data-item:nth-child(6n+3){
  background: rgba(0, 0, 0, 0.05);
}
.data-key{
  text-transform: capitalize;
  text-align: left;
  font-size: 0.6em;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0.4em;
}
.data-value{
  text-align: left;
}
/* Vue transition NEXT slides left */
.changedata-next-enter { opacity: 0; }
/* transform:  translate3d(40px, 0, 0);  */
/* .changedata-next-enter-to { transform: scale3d(1, 1, 1); } */
.changedata-next-enter-active,
.changedata-next-leave-active { transition: calc(var(--page-transition-time) / 2) ease; }
/* .changedata-next-leave { transform: scale3d(1, 1, 1); } */
.changedata-next-leave-to { opacity: 0; }
/* transform: translate3d(-40px, 0, 0);  */

/* Vue transition PREVIOUS slides right */
.changedata-previous-enter { opacity: 0;  }
/* transform: translate3d(-40px, 0, 0); */
/* .changedata-previous-enter-to { transform: scale3d(1, 1, 1); } */
.changedata-previous-enter-active,
.changedata-previous-leave-active { transition: calc(var(--page-transition-time) / 2) ease; }
/* .changedata-previous-leave { transform: scale3d(1, 1, 1); } */
.changedata-previous-leave-to { opacity: 0; }
/* transform:  translate3d(40px, 0, 0);  */

/* Navigation dots */
.navdot ul {
	position: relative;
	display: inline-block;
	margin: 0;
	padding: 0;
	list-style: none;
	cursor: default;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.navdot li {
	position: relative;
	display: block;
	float: left;
	margin: 0 11px;
	width: 16px;
	height: 16px;
	cursor: pointer;
}

.navdot li a {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	outline: none;
	border-radius: 50%;
	/* background-color: #fff; */
	background-color: rgba(0, 0, 0, 0.3);
	cursor: pointer;
	position: absolute;
}

.navdot li a:focus {
	outline: none;
}

.navdot li.empty_page a {
	overflow: hidden;
	background-color: transparent;
	box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.3);
	-webkit-transition: background 0.3s ease;
	transition: background 0.3s ease;
}

.navdot-dotmove li:last-child {
	position: absolute;
	left: 0;
	margin: 0;
	width: 38px;
	transition: transform var(--page-transition-time) ease;
}

.navdot-dotmove li:last-child::after {
	content: '';
	position: absolute;
	left: 50%;
	top: -1px;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background: rgba(0,0,0,0.8);
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.navdot-dotmove li.current:first-child ~ li:last-child {
	-webkit-transform: translateX(0%);
	transform: translateX(0%);
}

.navdot-dotmove li.current:nth-child(2) ~ li:last-child {
	-webkit-transform: translateX(100%);
	transform: translateX(100%);
}

.navdot-dotmove li.current:nth-child(3) ~ li:last-child {
	-webkit-transform: translateX(200%);
	transform: translateX(200%);
}

.navdot-dotmove li.current:nth-child(4) ~ li:last-child {
	-webkit-transform: translateX(300%);
	transform: translateX(300%);
}

.navdot-dotmove li.current:nth-child(5) ~ li:last-child {
	-webkit-transform: translateX(400%);
	transform: translateX(400%);
}

.navdot-dotmove li.current:nth-child(6) ~ li:last-child {
	-webkit-transform: translateX(500%);
	transform: translateX(500%);
}

.navdot-dotmove li a {
	-webkit-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
}

.navdot-dotmove li a:hover
/* .navdot-dotmove li a:focus  */ {
	/* background-color: rgba(0, 0, 0, 0.5); */
}
</style>
